(function () {
    'use strict';

    angular.module('adf.widget.myreverselinks', ['adf.provider'])
        .config(["dashboardProvider", function (dashboardProvider) {
            dashboardProvider
                .widget('myreverselinks', {
                    title: 'My Links',
                    description: 'Display a list of my links',
                    templateUrl: '/content/widgets/myreverselinks/view.html',
                    controller: 'MyReverseLinksController',
                    controllerAs: 'vm',
                    reload: true,
                    resolve: {
                        pagingParams: ['PaginationUtil', function (PaginationUtil) {
                            var params = {
                                page: '1',
                                sort: 'name,asc'
                            };

                            return {
                                page: PaginationUtil.parsePage(params.page),
                                sort: params.sort,
                                predicate: PaginationUtil.parsePredicate(params.sort),
                                ascending: PaginationUtil.parseAscending(params.sort),
                            };
                        }],
                        search: ['$scope', 'config', function ($scope, config) {
                            return config.search;
                        }],
                        category: ['$scope', 'config', function ($scope, config) {
                            return config.category;
                        }],
                        status: ['$scope', 'config', function ($scope, config) {
                            return config.status;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('member');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        }])
        .controller('MyReverseLinksController', MyReverseLinksController);

    MyReverseLinksController.$inject = ['$scope', '$state', 'MyReverseLinks', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'status', 'category', 'search'];

    function MyReverseLinksController($scope, $state, MyReverseLinks, ParseLinks, AlertService, pagingParams, paginationConstants, status, category, search) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.category = category;
        vm.status = status;

        loadAll();

        function loadAll() {
            MyReverseLinks.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                category: vm.category,
                status: vm.status,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                // vm.totalItems = headers('X-Total-Count');
                // vm.queryCount = vm.totalItems;
                vm.members = data;
                // vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
