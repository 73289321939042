(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .factory('ActionInfoService', ActionInfoService);

    ActionInfoService.$inject = ['$rootScope', '$http', 'Principal'];

    function ActionInfoService($rootScope, $http, Principal) {

        var dataPromise;

        var actions = null;

        var service = {
            getActionInfo: getActionInfo,
            getActions: getActions
        };

        $rootScope.$on('logoutSuccess', function () {
            dataPromise = null;
            actions = null;
            $rootScope.$broadcast('actionInfoLoaded');
        });

        $rootScope.$on('authenticationSuccess', function () {
            if (actions != null) {
                $rootScope.$broadcast('actionInfoLoaded');
            } else {
                getActionInfo();
            }
        });

        getActionInfo();

        return service;

        function getActionInfo() {
            if (!Principal.isAuthenticated()) {
                if (actions != null) {
                    actions = null;
                    $rootScope.$broadcast('actionInfoLoaded');
                }
                return null;
            }

            dataPromise = $http.get('api/action-info').then(function (result) {
                if (result.data) {

                    actions = result.data;

                    $rootScope.$broadcast('actionInfoLoaded');

                    return actions;
                }
            });
            return dataPromise;
        }

        function getActions() {
            return actions;
        }
    }
})();
