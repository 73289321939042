(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('notes', {
                parent: 'members-detail',
                url: '/notes?member&takenBy&page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'jobTicketApp.note.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/process/notes/notes.html',
                        controller: 'NotesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    member: null,
                    takenBy: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    member: ['$stateParams', function($stateParams) {
                        return $stateParams.member;
                    }],
                    takenBy: ['$stateParams', function($stateParams) {
                        return $stateParams.takenBy;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('note');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        .state('notes-detail', {
            parent: 'members-detail',
            url: '/notes/{note}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jobTicketApp.note.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/process/notes/notes-detail.html',
                    controller: 'NotesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('note');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Note', function($stateParams, Note) {
                    return Note.get({id : $stateParams.note}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'member-details',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params),
                        member: $state.params.member
                    };
                    return currentStateData;
                }]
            }
        })
        .state('notes.new', {
            parent: 'members-detail',
            url: '/notes/new?takenBy',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/notes/notes-dialog.html',
                    controller: 'NotesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['$stateParams', 'Note', function($stateParams, Note) {
                            return {
                                active: true,
                                noteDate: new Date(),
                                note: null,
                                reference: null,
                                resolutionDate: null,
                                resolutionNote: null,
                                subject: null,
                                dbTimeStamp: null,
                                memberId: $stateParams.id,
                                takenById: $stateParams.takenBy,
                                id: null
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                }, function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                });
            }]
        })
        .state('notes.edit', {
            parent: 'members-detail',
            url: '/note/{note}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/notes/notes-dialog.html',
                    controller: 'NotesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Note', function(Note) {
                            return Note.get({id : $stateParams.note}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                }, function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                });
            }]
        })
        .state('notes.delete', {
            parent: 'members-detail',
            url: '/note/{note}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/notes/notes-delete-dialog.html',
                    controller: 'NotesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Note', function(Note) {
                            return Note.get({id : $stateParams.note}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                }, function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                });
            }]
        });
    }

})();
