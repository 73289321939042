(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MembersDetailController', MembersDetailController);

    MembersDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'Member', 'Address', 'Note', 'Contact', 'Link', 'StatusResult', 'Category', 'MemberType', 'Action', 'Milestone', 'ActionInfoService', 'MemberActions', 'MemberSurveys', 'SurveyAnswer', 'DateUtils', 'Document'];

    function MembersDetailController($scope, $state, $rootScope, $stateParams, previousState, entity, Member, Address, Note, Contact, Link, StatusResult, Category, MemberType, Action, Milestone, ActionInfoService, MemberActions, MemberSurveys, SurveyAnswer, DateUtils, Document) {
        var vm = this;

        vm.member = entity;
        vm.previousState = previousState;
        vm.openCalendar = openCalendar;

        vm.dataLoading = true;

        $rootScope.back = function() {
            $state.go('home');//vm.previousState.name,vm.previousState.params);
        };

        vm.isActionMember = isActionMember;
        vm.actionType = actionType;

        updateActions();

        $scope.$on('actionInfoLoaded', function() {
            updateActions();
        });

        MemberType.get({
            id: vm.member.memberTypeId
        }, onMemberTypeSuccess);
        function onMemberTypeSuccess(data) {
            vm.memberTypeDetails = data;
            updateActions();
        }

        Member.get({
            id: 'current'
        }, onCurrentMemberSuccess);
        function onCurrentMemberSuccess(data) {
            vm.currentMember = data;
        }

        MemberActions.query({
            id: vm.member.id
        }, onMemberActionsSuccess);
        function onMemberActionsSuccess(data) {
            vm.memberActions = data;
        }

        MemberSurveys.query({
            id: vm.member.id
        }, onMemberSurveysSuccess, onMemberSurveysFailure);
        function onMemberSurveysSuccess(data) {
            vm.memberSurveyDetails = data;

            setupSurveyQuestions();

            vm.dataLoading = false;
        }
        function onMemberSurveysFailure() {
            vm.dataLoading = false;
        }

        vm.documents = Document.query({
            member: vm.member.id
        });

        var unsubscribe = $rootScope.$on('jobTicketApp:membersUpdate', function(event, result) {
            vm.member = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function updateActions() {
            vm.actions = ActionInfoService.getActions();
            if (angular.isObject(vm.memberTypeDetails)) {
                vm.action_new = isActionMember(vm.memberTypeDetails.code + ':new');
                vm.action_remove = isActionMember(vm.memberTypeDetails.code + ':remove');
                vm.action_list = isActionMember(vm.memberTypeDetails.code + ':list');
                vm.action_list_related = !vm.action_list && isActionMember(vm.memberTypeDetails.code + ':list-related');
                vm.action_view = isActionMember(vm.memberTypeDetails.code + ':view');
                vm.action_view_related = !vm.action_view && isActionMember(vm.memberTypeDetails.code + ':view-related');
                vm.action_notes = isActionMember(vm.memberTypeDetails.code + ':notes');
                vm.action_notes_related = !vm.action_notes && isActionMember(vm.memberTypeDetails.code + ':notes-related');
                vm.action_documents = isActionMember(vm.memberTypeDetails.code + ':documents');
                vm.action_documents_related = !vm.action_documents && isActionMember(vm.memberTypeDetails.code + ':documents-related');
            }
        }

        function isActionMember(actionCode) {
            if (angular.isObject(vm.actions)) {
                for (var i = 0; i < vm.actions.length; i++) {
                    if (vm.actions[i].actionCode === actionCode) {
                        return true;
                    }
                }
            }
            return false;
        }

        function actionType(action) {
            if (angular.isObject(action)) {
                if (action.newAction) {
                    return "new";
                } else if (action.listAction) {
                    return "list";
                }
            }
            return "action";
        }

        function openCalendar(date) {
        }

        function setupSurveyQuestions() {
            vm.memberSurveyDetails.surveyResponse = {};
            vm.memberSurveyDetails.selections = {};
            vm.memberSurveyDetails.entities = {};
            vm.memberSurveyDetails.datePickerOpenStatus = {};
            vm.memberSurveyDetails.dateformat = DateUtils.dateformat;
            vm.memberSurveyDetails.openCalendar = openCalendar;

            // Get all of the questions as a map for easy lookup
            if (vm.memberSurveyDetails.surveyDefinition) {
                vm.surveyQuestions = {};
                for (var i = 0, len = vm.memberSurveyDetails.surveyDefinition.sections.length; i < len; i++) {
                    getQuestionsFromSection(vm.memberSurveyDetails.surveyDefinition.sections[i]);
                }
            }

            // Create a map of the responses for access from the view
            if (vm.memberSurveyDetails.survey) {
                for (var i = 0, len = vm.memberSurveyDetails.survey.responses.length; i < len; i++) {

                    var response = vm.memberSurveyDetails.survey.responses[i];

                    vm.memberSurveyDetails.surveyResponse[response.questionId] = response;
                    vm.memberSurveyDetails.datePickerOpenStatus[response.questionId] = false;

                    // Check for a date field and map it if necessary
                    var question = findQuestionForResponse(response.questionId);
                    if (question) {
                        if (question.answerType === 'DATE_TIME') {
                            response.notes = DateUtils.convertDateTimeFromServer(response.notes);
                        } else {

                            switch (question.answerType) {
                                case 'MEMBER_LOOKUP':
                                case 'MULTI_MEMBER_LOOKUP':
                                case 'CURRENT_MEMBER_LOOKUP':
                                case 'PRINCIPAL_LINK_LOOKUP':
                                    if (response.notes && response.notes !== "") {
                                        vm.memberSurveyDetails.entities[question.id] = Member.get({id: response.notes});
                                    }
                                    break;

                                case 'CATEGORY_LOOKUP':
                                case 'MULTI_CATEGORY_LOOKUP':
                                    if (response.notes && response.notes !== "") {
                                        vm.memberSurveyDetails.entities[question.id] = Category.get({id: response.notes});
                                    }
                                    break;

                                case 'CHOICE':
                                case 'MULTI_CHOICE':
                                    if (response.answer && response.answer !== "") {
                                        vm.memberSurveyDetails.entities[question.id] = SurveyAnswer.get({id: response.answer});
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
        }

        function getQuestionsFromSection(section) {

            for (var i = 0, len = section.questions.length; i < len; i++) {
                var question = section.questions[i];
                vm.surveyQuestions[question.id] = question;
            }

            for (var i = 0, len = section.sections.length; i < len; i++) {
                getQuestionsFromSection(section.sections[i]);
            }

        }

        function findQuestionForResponse(questionId) {
            return vm.surveyQuestions[questionId];
        }
    }
})();
