// See http://stackoverflow.com/questions/28114970/angularjs-ng-options-using-number-for-model-does-not-select-initial-value
(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .directive('convertToNumber', convertToNumber);

    function convertToNumber() {
        var directive = {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return val != null ? parseInt(val, 10) : null;
                });
                ngModel.$formatters.push(function (val) {
                    return val != null ? '' + val : null;
                });
            }
        };

        return directive;
    }
})();
