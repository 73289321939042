(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('ReportDetailController', ReportDetailController);

    ReportDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Report', 'Member', 'Category'];

    function ReportDetailController($scope, $rootScope, $stateParams, entity, Report, Member, Category) {
        var vm = this;
        vm.report = entity;

        var unsubscribe = $rootScope.$on('jobTicketApp:reportUpdate', function(event, result) {
            vm.report = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
