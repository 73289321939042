(function() {
    'use strict';

    angular
        .module('jobTicketApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.tree',
            'infinite-scroll',
            'adf',
            'adf.structures.base',
            'adf.widget.clock',
            'adf.widget.weather',
            'adf.widget.randommsg',
            'adf.widget.iframe',
            'adf.widget.mytasks',
            'adf.widget.mylinks',
            'adf.widget.myreverselinks',
            'adf.widget.memberswithstatus',
            'adf.widget.membersummaries',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'angular-cron-gen',
            'angular.filter'
        ]).value ('adfTemplatePath', 'app/layouts/templates/')
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
