(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .directive('surveyQuestion', surveyQuestion);

    function surveyQuestion() {
        var directive = {
            restrict: 'E',
            scope: {
                memberSurveyDetails: '=',
                question: '='
            },
            templateUrl: 'app/process/surveys/survey-question.html',
            link: setup
        };

        return directive;
    }

    function setup(scope, element, attrs) {
        scope.isSectionVisible = function (section) {
            if (section.showWhenQuestion && section.showWhenAnswers.length > 0) {

            }
            return true;
        }
    }

})();
