(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('StatusResultDeleteController',StatusResultDeleteController);

    StatusResultDeleteController.$inject = ['$uibModalInstance', 'entity', 'StatusResult'];

    function StatusResultDeleteController($uibModalInstance, entity, StatusResult) {
        var vm = this;

        vm.statusResult = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            StatusResult.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
