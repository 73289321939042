(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('documents', {
                parent: 'members-detail',
                url: '/documents?member&loadedBy&page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'jobTicketApp.document.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/process/documents/documents.html',
                        controller: 'DocumentsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    member: null,
                    loadedBy: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    member: ['$stateParams', function($stateParams) {
                        return $stateParams.member;
                    }],
                    loadedBy: ['$stateParams', function($stateParams) {
                        return $stateParams.loadedBy;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        .state('documents-detail', {
            parent: 'members-detail',
            url: '/documents/{document}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jobTicketApp.document.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/process/documents/documents-detail.html',
                    controller: 'DocumentsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Document', function($stateParams, Document) {
                    return Document.get({member : $stateParams.member, category: $stateParams.category, name: $stateParams.name}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'member-details',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params),
                        member: $state.params.member
                    };
                    return currentStateData;
                }]
            }
        })
            .state('documents-download', {
                parent: 'members-detail',
                url: '/documents/download/{category}/{name}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'jobTicketApp.document.detail.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    location.href='/api/documents/download/' + $stateParams.id + '/' + $stateParams.category + '/' + $stateParams.name;
                }]
            })
        .state('documents.new', {
            parent: 'members-detail',
            url: '/documents/new?loadedBy',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/documents/documents-dialog.html',
                    controller: 'DocumentsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['$stateParams', 'Document', function($stateParams, Document) {
                            return {
                                name: null,
                                documentDate: null,
                                categoryId: null,
                                memberId: $stateParams.id,
                                loadedById: $stateParams.loadedBy,
                                id: null
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                }, function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                });
            }]
        })
        .state('documents.edit', {
            parent: 'members-detail',
            url: '/document/{document}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/documents/documents-dialog.html',
                    controller: 'DocumentsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Document', function(Document) {
                            return Document.get({member : $stateParams.member, category: $stateParams.category, name: $stateParams.name}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                }, function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                });
            }]
        })
        .state('documents.delete', {
            parent: 'members-detail',
            url: '/document/{category}/{name}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/documents/documents-delete-dialog.html',
                    controller: 'DocumentsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Document', function(Document) {
                            return Document.get({member : $stateParams.id, category: $stateParams.category, name: $stateParams.name}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                }, function() {
                    $state.go('members-detail', { 'member': $stateParams.id }, { reload: 'members-detail' });
                });
            }]
        });
    }

})();
