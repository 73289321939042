(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveyQuestionDetailController', SurveyQuestionDetailController);

    SurveyQuestionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SurveyQuestion', 'SurveySection'];

    function SurveyQuestionDetailController($scope, $rootScope, $stateParams, previousState, entity, SurveyQuestion, SurveySection) {
        var vm = this;

        vm.surveyQuestion = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:surveyQuestionUpdate', function(event, result) {
            vm.surveyQuestion = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
