(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'ProcessInfoService', 'MemberTypeNavigation', 'ActionInfoService', 'BootSwatchService'];

    function NavbarController ($state, $scope, Auth, Principal, ProfileService, LoginService, ProcessInfoService, MemberTypeNavigation, ActionInfoService, BootSwatchService) {
        var vm = this;

        vm.process = null;
        vm.actions = null;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.themes = null;
        vm.theme = null;

        BootSwatchService.get().then(function (themes) {
            vm.themes = themes;
            getTheme();
        });

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        $scope.$on('processInfoLoaded', function() {
            vm.process = ProcessInfoService.getProcess();
            getTheme();
        });

        $scope.$on('logoutSuccess', function() {
            setTheme(null);
        });

        $scope.$on('actionInfoLoaded', function() {
            vm.actions = ActionInfoService.getActions();
            vm.memberTypes = MemberTypeNavigation.query();
        });

        getTheme();

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.getProcessUrl = getProcessUrl;
        vm.isProcessAvailable = isProcessAvailable;
        vm.isActionAvailable = isActionAvailable;

        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function isProcessAvailable() {
            return angular.isObject(vm.process);
        }

        function getProcessUrl(href) {
            var vars = {};

            if (isProcessAvailable()) {
                vars.process = vm.process.code;
            }
            return vm.$state.href(href, vars);
        }

        function isActionAvailable(actionCode) {
            if (angular.isObject(vm.actions)) {
                for (var i = 0; i < vm.actions.length; i++) {
                    if (vm.actions[i].actionCode === actionCode) {
                        return true;
                    }
                }
            }
            return false;
        }

        function getTheme() {
            if (!vm.theme && vm.process) {
                if (vm.process.theme) {
                    angular.forEach(vm.themes, function (theme) {
                        if (theme.name === vm.process.theme) {
                            setTheme(theme);
                        }
                    });
                }
            }
        }

        function setTheme(theme) {
            vm.theme = theme;

            if (theme) {
                $("#bootswatch-css").attr("href", theme.css);
            } else {
                $("#bootswatch-css").attr("href", '');
            }
        }
    }
})();
