(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('members', {
            parent: 'process',
            url: '/members?memberType&parent&principal&action&task&statusValue&statusValueGroup&page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jobTicketApp.member.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/process/members/members.html',
                    controller: 'MembersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 's.statusValue.statusOrder,asc',
                    squash: true
                },
                search: null,
                memberType: null,
                parent: null,
                principal: null,
                statusValue: null,
                statusValueGroup: null,
                action: null,
                task:null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                //   memberType: ['$stateParams', function($stateParams) {
                //     return $stateParams.memberType;
                // }],
                // parent: ['$stateParams', function($stateParams) {
                //     return $stateParams.parent;
                // }],
                // principal: ['$stateParams', function($stateParams) {
                //     return $stateParams.principal;
                // }],
                // statusValue: ['$stateParams', function($stateParams) {
                //     return $stateParams.statusValue;
                // }],
                // statusValueGroup: ['$stateParams', function($stateParams) {
                //     return $stateParams.statusValueGroup;
                // }],
                // action: ['$stateParams', function($stateParams) {
                //     return $stateParams.action;
                // }],
                // task: ['$stateParams', function($stateParams) {
                //     return $stateParams.task;
                // }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('member');
                    $translatePartialLoader.addPart('survey');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('members-detail', {
            parent: 'process',
            url: '/members/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jobTicketApp.member.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/process/members/members-detail.html',
                    controller: 'MembersDetailController',
                    controllerAs: 'vm'
                },
                'no-survey@members-detail': {
                    templateUrl: 'app/process/members/members-detail-no-survey.html'
                },
                'survey@members-detail': {
                    templateUrl: 'app/process/members/members-detail-survey.html'
                },
                'notes@members-detail': {
                    templateUrl: 'app/process/members/members-detail-notes.html'
                },
                'documents@members-detail': {
                    templateUrl: 'app/process/members/members-detail-documents.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('member');
                    $translatePartialLoader.addPart('survey');
                    $translatePartialLoader.addPart('note');
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Member', function($stateParams, Member) {
                    return Member.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'members',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params),
                        memberType: $state.params.memberType,
                        parent: $state.params.parent,
                        principal: $state.params.principal,
                        statusValue: $state.params.statusValue,
                        statusValueGroup: $state.params.statusValueGroup,
                        action: $state.params.action,
                        task: $state.params.task
                    };
                    return currentStateData;
                }]
            }
        })
        .state('members.new', {
            parent: 'members',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            previousState: ["$state", function ($state) {
                var currentStateData = {
                    name: $state.current.name || 'members',
                    params: $state.params,
                    url: $state.href($state.current.name, $state.params),
                    memberType: $state.params.memberType,
                    parent: $state.params.parent,
                    principal: $state.params.principal,
                    statusValue: $state.params.statusValue,
                    statusValueGroup: $state.params.statusValueGroup,
                    action: $state.params.action,
                    task: $state.params.task
                };
                return currentStateData;
            }],
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/members/members-dialog.html',
                    controller: 'MembersDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    views: {
                    },
                    resolve: {
                        entity: ['$stateParams', 'Member', function($stateParams, Member) {
                            return {
                                active: false,
                                code: null,
                                emailAddress: null,
                                endDate: null,
                                name: null,
                                description: null,
                                path: null,
                                password: null,
                                startDate: null,
                                dbTimeStamp: null,
                                memberTypeId: $stateParams.memberType,
                                parentId: $stateParams.parent,
                                principalId: $stateParams.principal,
                                statusValueId: $stateParams.statusValue,
                                actionId: $stateParams.action,
                                taskId: $stateParams.task,
                                id: null
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('members', null, { reload: 'members' });
                }, function() {
                    $state.go('members');
                });
            }]
        })
        .state('members.edit', {
            parent: 'members',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/members/members-dialog.html',
                    controller: 'MembersDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Member', function(Member) {
                            return Member.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('members-detail', {id : $stateParams.id}, { reload: 'members' });
                }, function() {
                    $state.go('members-detail', {id : $stateParams.id}, { reload: 'members' });
                });
            }]
        })
        .state('members.delete', {
            parent: 'members',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/process/members/members-delete-dialog.html',
                    controller: 'MembersDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Member', function(Member) {
                            return Member.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('members', null, { reload: 'members' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('members.pdf', {
            parent: 'members',
            url: '/pdf/{id}/{category}/{name}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jobTicketApp.report.execute.title'
            },
            onEnter: ['$stateParams', '$state', '$timeout', function($stateParams, $state, $timeout) {
                location.href='/api/reports/execute/' + $stateParams.category + '/' + $stateParams.name + '?memberId=' + $stateParams.id;
                $timeout(function () {
                    $state.go('members-detail', {id : $stateParams.id}, { reload: 'members' });
                });
            }]
        })
        ;
    }

})();
