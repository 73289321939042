(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveyDefinitionDeleteController',SurveyDefinitionDeleteController);

    SurveyDefinitionDeleteController.$inject = ['$uibModalInstance', 'entity', 'SurveyDefinition'];

    function SurveyDefinitionDeleteController($uibModalInstance, entity, SurveyDefinition) {
        var vm = this;

        vm.surveyDefinition = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SurveyDefinition.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
