(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MembersController', MembersController);

    MembersController.$inject = ['$scope', '$state', 'Member', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'MemberType', 'ActionInfoService', 'StatusValue', 'StatusValueGroup'];

    function MembersController ($scope, $state, Member, ParseLinks, AlertService, pagingParams, paginationConstants, MemberType, ActionInfoService, StatusValue, StatusValueGroup) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.updateActions = updateActions;
        vm.loadPage = loadPage;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.memberType = $state.params.memberType;
        vm.parent = $state.params.parent;
        vm.principal = $state.params.principal;
        vm.statusValue = $state.params.statusValue;
        vm.statusValueGroup = $state.params.statusValueGroup;
        vm.isActionMember = isActionMember;
        vm.updateActions = updateActions;

        $scope.$on('actionInfoLoaded', function() {
            vm.updateActions();
        });

        $scope.updateStatusValue = function () {
            pagingParams.page = 1;
            vm.loadAll();
        };

        $scope.updateStatusValueGroup = function() {
             pagingParams.page = 1;
            vm.loadAll();
        };

        if (vm.parent) {
            Member.get({
                id: vm.parent
            }, onParentSuccess);
        }
        function onParentSuccess(data) {
            vm.parentDetails = data;
        }
        if (vm.principal) {
            Member.get({
                id: vm.principal
            }, onPrincipalSuccess);
        }
        function onPrincipalSuccess(data) {
            vm.principalDetails = data;
        }
        MemberType.get({
            id: vm.memberType
        }, onMemberTypeSuccess);
        function onMemberTypeSuccess(data) {
            vm.memberTypeDetails = data;
            vm.updateActions();

            StatusValue.query({
                parent: null,
                sort: 'statusOrder',
                code: vm.memberTypeDetails.code
            }, function(data) {
                vm.statusValues = data;
            });

            StatusValueGroup.query({
                parent: null,
                sort: 'groupOrder',
                code: vm.memberTypeDetails.code
            }, function(data) {
                vm.statusValueGroups = data;
                if (!vm.statusValueGroup && angular.isObject(vm.statusValueGroups)) {
                    for (var i = 0; i < vm.statusValueGroups.length; i++) {
                        if (vm.statusValueGroups[i].firstFilter) {
                            vm.statusValueGroup = vm.statusValueGroups[i].id;
                            vm.loadAll();
                            break;
                        }
                    }
                }

            });
        }

        vm.loadAll();
        vm.updateActions();

        function loadAll () {
            Member.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                memberType: vm.memberType,
                parent: vm.parent,
                principal: vm.principal,
                statusValue: vm.statusValue,
                statusValueGroup: vm.statusValueGroup
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.members = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                memberType: vm.memberType,
                parent: vm.parent,
                principal: vm.principal,
                statusValue: vm.statusValue,
                statusValueGroup: vm.statusValueGroup
            });
        }

        function updateActions() {
            vm.actions = ActionInfoService.getActions();

            if (angular.isObject(vm.memberTypeDetails)) {
                vm.newAction = findAction(vm.memberTypeDetails.code + ':new');
                vm.action_new = isActionMember(vm.memberTypeDetails.code + ':new');
                vm.action_remove = isActionMember(vm.memberTypeDetails.code + ':remove');
                vm.action_list = isActionMember(vm.memberTypeDetails.code + ':list');
                vm.action_list_related = !vm.action_list && isActionMember(vm.memberTypeDetails.code + ':list-related');
                vm.action_view = isActionMember(vm.memberTypeDetails.code + ':view');
                vm.action_view_related = !vm.action_view && isActionMember(vm.memberTypeDetails.code + ':view-related');
                vm.action_notes = isActionMember(vm.memberTypeDetails.code + ':notes');
                vm.action_notes_related = !vm.action_notes && isActionMember(vm.memberTypeDetails.code + ':notes-related');
                vm.action_documents = isActionMember(vm.memberTypeDetails.code + ':documents');
                vm.action_documents_related = !vm.action_documents && isActionMember(vm.memberTypeDetails.code + ':documents-related');
                vm.filter_status = isActionMember(vm.memberTypeDetails.code + ':filter-status');
                vm.filter_status_group = isActionMember(vm.memberTypeDetails.code + ':filter-status-group');
            }
        }

        function isActionMember(actionCode) {
            if (angular.isObject(vm.actions)) {
                for (var i = 0; i < vm.actions.length; i++) {
                    if (vm.actions[i].actionCode === actionCode) {
                        return true;
                    }
                }
            }
            return false;
        }
        function findAction(actionCode) {
            if (angular.isObject(vm.actions)) {
                for (var i = 0; i < vm.actions.length; i++) {
                    if (vm.actions[i].actionCode === actionCode) {
                        return vm.actions[i];
                    }
                }
            }
            return null;
        }
    }
})();
