(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('ActionCycleDialogController', ActionCycleDialogController);

    ActionCycleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ActionCycle', 'SurveyDefinition', 'Member', 'Action', 'ActionPlan'];

    function ActionCycleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ActionCycle, SurveyDefinition, Member, Action, ActionPlan) {
        var vm = this;

        vm.actionCycle = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.surveydefinitions = SurveyDefinition.query();
        vm.members = Member.query();
        vm.actions = Action.query();
        vm.actionplans = ActionPlan.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.actionCycle.id !== null) {
                ActionCycle.update(vm.actionCycle, onSaveSuccess, onSaveError);
            } else {
                ActionCycle.save(vm.actionCycle, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:actionCycleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;
        vm.datePickerOpenStatus.scheduledDate = false;
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
