(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['DashboardInfoService', 'ProcessInfoService', '$scope', 'Principal', 'LoginService', '$state'];

    function HomeController (DashboardInfoService, ProcessInfoService, $scope, Principal, LoginService, BootSwatchService, $state) {
        var vm = this;

        vm.process = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.hasProcess = hasProcess;
        vm.dashboard = null;
        vm.hasDashboard = hasDashboard;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $scope.$on('processInfoLoaded', function() {
            getProcess();
        });

        $scope.$on('dashboardInfoLoaded', function() {
            getDashboard();
        });

        $scope.$on('logoutSuccess', function() {
            getAccount();
            getProcess();
            getDashboard();
        });

        getAccount();
        getProcess();
        getDashboard();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function getDashboard() {
            vm.dashboard = generateDashboard(DashboardInfoService.getDashboard());
        }
        function getProcess() {
            vm.process = ProcessInfoService.getProcess();
            if (vm.process && !vm.process.theme) {
                vm.process.theme = 'Sandstone';
            }
        }
        function register () {
            $state.go('register');
        }
        function hasProcess() {
            return angular.isObject(vm.process);
        }
        function hasDashboard() {
            return angular.isObject(vm.dashboard);
        }

        function generateDashboard(savedDashboard) {
            if (savedDashboard) {
                var model = null;// localStorageService.get('widgetSampleDashboard');
                if (!model){
                    model = angular.fromJson(savedDashboard.layout);
                }
                var dashboard = {
                    structure: savedDashboard.structure,
                    editMode: false,
                    model: model
                };
                // $scope.$on('adfDashboardChanged', function (event, name, model) {
                //     localStorageService.set(name, model);
                // });
                return dashboard;
            }
        }
    }
})();
