(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .factory('BootSwatchService', BootSwatchService);

    BootSwatchService.$inject = ['$http'];

    function BootSwatchService ($http) {
        return {
            get: function() {
                return $http.get('https://bootswatch.com/api/3.json').then(function (response) {
                    return response.data.themes;
                });
            }
        };
    }
})();
