(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .factory('DashboardInfoService', DashboardInfoService);

    DashboardInfoService.$inject = ['$rootScope', '$http', 'Principal'];

    function DashboardInfoService($rootScope, $http, Principal) {

        var dataPromise;

        var dashboard = null;

        var service = {
            getDashboardInfo: getDashboardInfo,
            getDashboard: getDashboard
        };

        $rootScope.$on('logoutSuccess', function () {
            dataPromise = null;
            dashboard = null;
            $rootScope.$broadcast('dashboardInfoLoaded');
        });

        $rootScope.$on('authenticationSuccess', function () {
            if (dashboard != null) {
                $rootScope.$broadcast('dashboardInfoLoaded');
            } else {
                getDashboardInfo();
            }
        });

        getDashboardInfo();

        return service;

        function getDashboardInfo() {
            if (!Principal.isAuthenticated()) {
                if (dashboard != null) {
                    dashboard = null;
                    $rootScope.$broadcast('dashboardInfoLoaded');
                }
                return null;
            }

            dataPromise = $http.get('api/dashboard-info').then(function (result) {
                if (result.data.name) {
                    var response = {};
                    response.id = result.data.id;
                    response.structure = result.data.structure;
                    response.headerImage = result.data.headerImage;
                    response.name = result.data.name;
                    response.layout = result.data.layout;
                    response.memberId = result.data.memberId;

                    dashboard = response;
                    $rootScope.$broadcast('dashboardInfoLoaded');

                    return response;
                }
            });
            return dataPromise;
        }

        function getDashboard() {
            return dashboard;
        }
    }
})();
