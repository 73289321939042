(function() {
    'use strict';

angular
	.module('jobTicketApp')
	.controller('JobDeleteController',JobDeleteController);
	
	function JobDeleteController($uibModalInstance, entity, Job) {
	var vm = this;
        vm.job = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (name) {
            Job.delete({name: name},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
