(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('BootswatchController', BootswatchController);

    BootswatchController.$inject = ['$scope', 'BootSwatchService'];

    function BootswatchController ($scope, BootSwatchService) {
        BootSwatchService.get().then(function(themes) {
            $scope.themes = themes;
            $scope.themes.unshift({name:'Default',css:''});
        });
    }
})();
