(function () {
    'use strict';

    angular.module('adf.widget.membersummaries', ['adf.provider'])
        .config(["dashboardProvider", function (dashboardProvider) {
            dashboardProvider
                .widget('membersummaries', {
                    title: 'Member Summaries',
                    description: 'Display a monthly summary of members',
                    templateUrl: '/content/widgets/membersummaries/view.html',
                    controller: 'MemberSummariesController',
                    controllerAs: 'vm',
                    reload: true,
                    resolve: {
                        memberType: ['$scope', 'config', function ($scope, config) {
                            return config.memberType;
                        }],
                        startStatus: ['$scope', 'config', function ($scope, config) {
                            return config.startStatus;
                        }],
                        endStatus: ['$scope', 'config', function ($scope, config) {
                            return config.endStatus;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('member');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        }])
        .controller('MemberSummariesController', MemberSummariesController);

    MemberSummariesController.$inject = ['$scope', '$state', '$filter', 'MemberSummaries', 'ParseLinks', 'AlertService', 'memberType', 'startStatus', 'endStatus'];

    function MemberSummariesController($scope, $state, $filter, MemberSummaries, ParseLinks, AlertService, memberType, startStatus, endStatus) {
        var vm = this;

        vm.reportMonthFormat = 'MMM yyyy';
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.loadAll = loadAll;

        vm.memberType = memberType;
        vm.startStatus = startStatus;
        vm.endStatus = endStatus;

        loadAll();

        function loadAll() {

            var reportMonth;
            if (vm.reportMonth) {
                reportMonth = $filter('date')(vm.reportMonth,'yyyy-MM');
            }
            MemberSummaries.query({
                reportMonth: reportMonth,
                memberType: vm.memberType,
                startStatus: vm.startStatus,
                endStatus: vm.endStatus
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                processRawData(data);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            function processRawData(data) {
                vm.memberSummaries = {};
                vm.reportMonth = data.startDate;

                for (var i = 0; i < data.memberSummaries.length; i++) {
                    var row = data.memberSummaries[i];
                    var memberSummary = vm.memberSummaries[row.memberCode];
                    if (!memberSummary) {
                        memberSummary = {};
                        memberSummary.memberCode = row.memberCode;
                        memberSummary.memberName = row.memberName;
                        memberSummary.numberOpened = 0;
                        memberSummary.numberComplete = 0;
                        memberSummary.totalCompleteTime = 0;
                        memberSummary.averageCompleteTime = 0;

                        vm.memberSummaries[row.memberCode] = memberSummary;
                    }

                    if (row.openDate) {
                        if (row.openDate.getTime() >= data.startDate.getTime()) {
                            memberSummary.numberOpened++;
                        }
                    }
                    if (row.completeDate) {
                        if (row.completeDate.getTime() <= data.endDate.getTime()) {
                            memberSummary.numberComplete++;

                            if (row.elapsedTime) {
                                memberSummary.totalCompleteTime += row.elapsedTime;
                            }
                        }
                    }
                }
                for (var memberCode in vm.memberSummaries) {

                    var memberSummary1 = vm.memberSummaries[memberCode];
                    if (memberSummary1.totalCompleteTime > 0 && memberSummary1.numberComplete > 0) {
                        memberSummary1.averageCompleteTime = memberSummary1.totalCompleteTime / memberSummary1.numberComplete / 24;
                    }
                }
                vm.memberSummaries = Object.values(vm.memberSummaries)
                    .sort(function (a, b) {
                        return ('' + a.memberName).localeCompare(b.memberName);
                    });
            }
        }
        vm.datePickerOpenStatus.reportMonth = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
