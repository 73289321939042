(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('NoteDetailController', NoteDetailController);

    NoteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Note', 'Member', 'Category', 'NoteTemplate'];

    function NoteDetailController($scope, $rootScope, $stateParams, previousState, entity, Note, Member, Category, NoteTemplate) {
        var vm = this;

        vm.note = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:noteUpdate', function(event, result) {
            vm.note = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
