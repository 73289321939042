(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('StatusResultDialogController', StatusResultDialogController);

    StatusResultDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StatusResult', 'StatusValue', 'Member'];

    function StatusResultDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StatusResult, StatusValue, Member) {
        var vm = this;

        vm.statusResult = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.statusvalues = StatusValue.query();
        vm.members = Member.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.statusResult.id !== null) {
                StatusResult.update(vm.statusResult, onSaveSuccess, onSaveError);
            } else {
                StatusResult.save(vm.statusResult, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:statusResultUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.expiredDate = false;
        vm.datePickerOpenStatus.statusDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
