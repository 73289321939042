(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .directive('surveySection', surveySection);

    function surveySection() {
        var directive = {
            restrict: 'E',
            scope: {
                memberSurveyDetails: '=',
                section: '='
            },
            templateUrl: 'app/process/surveys/survey-section.html',
            link: setup
        };

        return directive;

        function setup(scope, element, attrs) {
            scope.cronOptions = {
                "quartz": true
            };
        }
    }
})();
