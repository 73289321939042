(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('DocumentDialogController', DocumentDialogController);

    DocumentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Upload', 'Document', 'Member', 'Category'];

    function DocumentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Upload, Document, Member, Category) {
        var vm = this;
        vm.document = entity;
        vm.file = null;
        vm.members = Member.query();
        vm.categories = Category.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('jobTicketApp:documentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.document.name = vm.file.name;
            uploadUsingUpload(vm.file, vm.document);
        };

        function uploadUsingUpload(file, document) {
            file.upload = Upload.upload({
                url: 'api/documents/upload',
                headers: {
                    'Content-Type': undefined
                },
                data: {document: angular.toJson(document), file: file}
            });

            file.upload.then(onSaveSuccess, function (response) {
                if (response.status > 0) {
                    $scope.errorMsg = response.status + ': ' + response.data;
                }
                onSaveError();
            }, function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
