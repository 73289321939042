(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('DefaultSurveyResponseDetailController', DefaultSurveyResponseDetailController);

    DefaultSurveyResponseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DefaultSurveyResponse', 'Member', 'SurveyDefinition', 'SurveyQuestion', 'SurveyAnswer'];

    function DefaultSurveyResponseDetailController($scope, $rootScope, $stateParams, previousState, entity, DefaultSurveyResponse, Member, SurveyDefinition, SurveyQuestion, SurveyAnswer) {
        var vm = this;

        vm.defaultSurveyResponse = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:defaultSurveyResponseUpdate', function(event, result) {
            vm.defaultSurveyResponse = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
