(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('DocumentDetailController', DocumentDetailController);

    DocumentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Document', 'Member', 'Category'];

    function DocumentDetailController($scope, $rootScope, $stateParams, entity, Document, Member, Category) {
        var vm = this;
        vm.document = entity;

        var unsubscribe = $rootScope.$on('jobTicketApp:documentUpdate', function(event, result) {
            vm.document = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
