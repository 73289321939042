(function () {
    'use strict';

    angular.module('adf.widget.memberswithstatus', ['adf.provider'])
        .config(["dashboardProvider", function (dashboardProvider) {
            dashboardProvider
                .widget('memberswithstatus', {
                    title: 'Members With Status',
                    description: 'Display a list of members with a given status',
                    templateUrl: '/content/widgets/memberswithstatus/view.html',
                    controller: 'MembersWithStatusController',
                    controllerAs: 'vm',
                    reload: true,
                    resolve: {
                        pagingParams: ['PaginationUtil', function (PaginationUtil) {
                            var params = {
                                page: '1',
                                sort: 'name,asc'
                            };

                            return {
                                page: PaginationUtil.parsePage(params.page),
                                sort: params.sort,
                                predicate: PaginationUtil.parsePredicate(params.sort),
                                ascending: PaginationUtil.parseAscending(params.sort),
                            };
                        }],
                        search: ['$scope', 'config', function ($scope, config) {
                            return config.search;
                        }],
                        status: ['$scope', 'config', function ($scope, config) {
                            return config.status;
                        }],
                        memberType: ['$scope', 'config', function ($scope, config) {
                            return config.memberType;
                        }],
                        parent: ['$scope', 'config', function ($scope, config) {
                            return config.parent;
                        }],
                        category: ['$scope', 'config', function ($scope, config) {
                            return config.category;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('member');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        }])
        .controller('MembersWithStatusController', MembersWithStatusController);

    MembersWithStatusController.$inject = ['$scope', '$state', 'MembersWithStatus', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'search', 'status', 'memberType', 'parent', 'category'];

    function MembersWithStatusController($scope, $state, MembersWithStatus, ParseLinks, AlertService, pagingParams, paginationConstants, search, status, memberType, parent, category) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.status = status;
        vm.memberType = memberType;
        vm.parent = parent;
        vm.category = category;

        loadAll();

        function loadAll() {
            MembersWithStatus.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                status: vm.status,
                memberType: vm.memberType,
                parent: vm.parent,
                category: vm.category,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                // vm.totalItems = headers('X-Total-Count');
                // vm.queryCount = vm.totalItems;
                vm.members = data;
                // vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
