(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveyDefinitionDetailController', SurveyDefinitionDetailController);

    SurveyDefinitionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SurveyDefinition', 'SurveySection', 'Category'];

    function SurveyDefinitionDetailController($scope, $rootScope, $stateParams, previousState, entity, SurveyDefinition, SurveySection, Category) {
        var vm = this;

        vm.surveyDefinition = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:surveyDefinitionUpdate', function(event, result) {
            vm.surveyDefinition = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
