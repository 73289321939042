(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MapReferenceDetailController', MapReferenceDetailController);

    MapReferenceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MapReference', 'Member', 'Map'];

    function MapReferenceDetailController($scope, $rootScope, $stateParams, previousState, entity, MapReference, Member, Map) {
        var vm = this;

        vm.mapReference = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:mapReferenceUpdate', function(event, result) {
            vm.mapReference = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
