(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MembersDialogController', MembersDialogController);

    MembersDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', 'entity', 'Member', 'Address', 'Note', 'Contact', 'Link', 'StatusResult', 'CategoryNavigation', 'Category', 'MemberTypeNavigation', 'MemberType', 'Action', 'Task', 'Milestone', 'User', 'MemberSurveys', 'DateUtils'];

    function MembersDialogController ($timeout, $scope, $state, $stateParams, $uibModalInstance, entity, Member, Address, Note, Contact, Link, StatusResult, CategoryNavigation, Category, MemberTypeNavigation, MemberType, Action, Task, Milestone, User, MemberSurveys, DateUtils) {
        var vm = this;

        vm.member = entity;
        vm.actionId = $stateParams.action;
        vm.taskId = $stateParams.task;
        vm.parentId = $stateParams.parent;
        vm.principalId = $stateParams.principal;
        vm.memberTypeId = $stateParams.memberType;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.dataLoading = true;

        if (vm.memberTypeId) {
            MemberType.get({
                id: vm.memberTypeId
            }, onMemberTypeSuccess);
        }
        function onMemberTypeSuccess(data) {
            vm.memberTypeDetails = data;
        }

        MemberSurveys.query({
            id: vm.member.id,
            memberType: vm.memberTypeId,
            parent: vm.parentId,
            principal: vm.principalId,
            action: vm.actionId,
            task: vm.taskId
        }, onMemberSurveysSuccess, onMemberSurveysFailure);
        function onMemberSurveysSuccess(data) {
            vm.memberSurveyDetails = data;

            setupSurveyQuestions();

            vm.dataLoading = false;
        }

        function onMemberSurveysFailure() {
            vm.dataLoading = false;
        }

        Action.get({
            id: vm.actionId
        }, onActionsSuccess);
        function onActionsSuccess(data) {
            vm.action = data;
        }

        if (vm.taskId) {
            Task.get({
                id: vm.taskId
            }, onTaskSuccess);
        }
        function onTaskSuccess(data) {
            vm.task = data;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.memberSurveyDetails.survey.id !== null) {
                MemberSurveys.update(vm.memberSurveyDetails, onSaveSuccess, onSaveError);
            } else {
                MemberSurveys.update(vm.memberSurveyDetails, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('jobTicketApp:membersUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;

            if (vm.member.id !== null) {
                window.history.back();
                window.history.back();
            } else {
                $state.go('members-detail', {id:result.id});
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            for (var question in vm.memberSurveyDetails.datePickerOpenStatus) {
                vm.memberSurveyDetails.datePickerOpenStatus[question] = false;
            }
            vm.memberSurveyDetails.datePickerOpenStatus[date] = true;
        }

        function setupSurveyQuestions() {
            vm.memberSurveyDetails.surveyResponse = {};
            vm.memberSurveyDetails.selections = {};
            vm.memberSurveyDetails.entities = {};
            vm.memberSurveyDetails.datePickerOpenStatus = {};
            vm.memberSurveyDetails.dateformat = DateUtils.dateformat;
            vm.memberSurveyDetails.openCalendar = openCalendar;

            // Get all of the questions as a map for easy lookup
            if (vm.memberSurveyDetails.surveyDefinition) {
                vm.surveyQuestions = {};
                for (var i = 0, len = vm.memberSurveyDetails.surveyDefinition.sections.length; i < len; i++) {
                    getQuestionsFromSection(vm.memberSurveyDetails.surveyDefinition.sections[i]);
                }
            }

            // Load any selections required
            angular.forEach(vm.surveyQuestions, function (surveyQuestion) {

                switch (surveyQuestion.answerType) {
                    case 'MEMBER_LOOKUP':
                    case 'MULTI_MEMBER_LOOKUP':
                        vm.memberSurveyDetails.selections[surveyQuestion.id] = Member.query({memberType: surveyQuestion.memberTypeId, category: surveyQuestion.memberCategoryId});
                        break;
                    case 'PRINCIPAL_LINK_LOOKUP':
                        vm.memberSurveyDetails.selections[surveyQuestion.id] = Link.query({member: vm.memberSurveyDetails.member.principalId, memberType: surveyQuestion.memberTypeId, category: surveyQuestion.memberCategoryId});
                        break;
                    case 'CHOICE':
                    case 'MULTI_CHOICE':
                    case 'CATEGORY_LOOKUP':
                    case 'MULTI_CATEGORY_LOOKUP':
                        loadSelectionsForQuestion(surveyQuestion);
                        break;
                }
            });

            // Create a map of the responses for access from the view
            if (vm.memberSurveyDetails.survey) {
                for (var i = 0, len = vm.memberSurveyDetails.survey.responses.length; i < len; i++) {

                    var response = vm.memberSurveyDetails.survey.responses[i];

                    vm.memberSurveyDetails.surveyResponse[response.questionId] = response;
                    vm.memberSurveyDetails.datePickerOpenStatus[response.questionId] = false;

                    // Check for a date field and map it if necessary
                    var question = findQuestionForResponse(response.questionId);
                    if (question) {
                        if (question.answerType === 'DATE_TIME') {
                            response.notes = DateUtils.convertDateTimeFromServer(response.notes);
                        } else if (question.readOnly) {

                            switch (question.answerType) {
                                case 'MEMBER_LOOKUP':
                                case 'MULTI_MEMBER_LOOKUP':
                                case 'CURRENT_MEMBER_LOOKUP':
                                case 'PRINCIPAL_LINK_LOOKUP':
                                    if (response.notes && response.notes !== "") {
                                        vm.memberSurveyDetails.entities[question.id] = Member.get({id: response.notes});
                                    }
                                    break;

                                case 'CATEGORY_LOOKUP':
                                case 'MULTI_CATEGORY_LOOKUP':
                                    if (response.notes && response.notes !== "") {
                                        vm.memberSurveyDetails.entities[question.id] = Category.get({id: response.notes});
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
        }

        function loadSelectionsForQuestion(surveyQuestion) {
            if (surveyQuestion.memberCategoryId) {
                vm.memberSurveyDetails.selections[surveyQuestion.id] = CategoryNavigation.query({parent: surveyQuestion.memberCategoryId});
            } else if (surveyQuestion.answerCategoryId) {
                vm.memberSurveyDetails.selections[surveyQuestion.id] = CategoryNavigation.query({parent: surveyQuestion.answerCategoryId});
            } else if (surveyQuestion.memberTypeId) {
                    vm.memberSurveyDetails.selections[surveyQuestion.id] = MemberTypeNavigation.query({parent: surveyQuestion.memberTypeId});
            }
        }

        function getQuestionsFromSection(section) {

            for (var i = 0, len = section.questions.length; i < len; i++) {
                var question = section.questions[i];
                vm.surveyQuestions[question.id] = question;
            }

            for (var i = 0, len = section.sections.length; i < len; i++) {
                getQuestionsFromSection(section.sections[i]);
            }

        }

        function findQuestionForResponse(questionId) {
            return vm.surveyQuestions[questionId];
        }
    }
})();
