(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveyResponseDialogController', SurveyResponseDialogController);

    SurveyResponseDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SurveyResponse', 'StatusResult', 'SurveyQuestion', 'SurveyAnswer', 'Survey'];

    function SurveyResponseDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SurveyResponse, StatusResult, SurveyQuestion, SurveyAnswer, Survey) {
        var vm = this;

        vm.surveyResponse = entity;
        vm.clear = clear;
        vm.save = save;
        vm.statusresults = StatusResult.query();
        vm.surveyquestions = SurveyQuestion.query();
        vm.surveyanswers = SurveyAnswer.query();
        vm.surveys = Survey.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.surveyResponse.id !== null) {
                SurveyResponse.update(vm.surveyResponse, onSaveSuccess, onSaveError);
            } else {
                SurveyResponse.save(vm.surveyResponse, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:surveyResponseUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
