(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MapDetailController', MapDetailController);

    MapDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Map', 'Member', 'Resource'];

    function MapDetailController($scope, $rootScope, $stateParams, previousState, entity, Map, Member, Resource) {
        var vm = this;

        vm.map = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:mapUpdate', function(event, result) {
            vm.map = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
