(function() {
  'use strict';

  angular.module('adf.widget.mytasks', ['adf.provider'])
    .config(["dashboardProvider", function (dashboardProvider) {
      dashboardProvider
        .widget('mytasks', {
          title: 'My Tasks',
          description: 'Display a list of my tasks',
          templateUrl: '/content/widgets/mytasks/view.html',
          controller: 'MyTasksController',
          controllerAs: 'vm',
          reload: true,
          resolve: {
            pagingParams: ['PaginationUtil', function (PaginationUtil) {
              var params = {
                page: '1',
                sort: 'scheduledDate,asc',
                search: null
              };

              return {
                page: PaginationUtil.parsePage(params.page),
                sort: params.sort,
                predicate: PaginationUtil.parsePredicate(params.sort),
                ascending: PaginationUtil.parseAscending(params.sort),
                search: params.search
              };
            }],
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('task');
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            }]
          }
        });
    }])
    .controller('MyTasksController', MyTasksController);

  MyTasksController.$inject = ['$scope', '$state', 'MyTasks', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

  function MyTasksController($scope, $state, MyTasks, ParseLinks, AlertService, pagingParams, paginationConstants) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.itemsPerPage = paginationConstants.itemsPerPage;

    loadAll();

    function loadAll() {
      MyTasks.query({
        page: pagingParams.page - 1,
        size: vm.itemsPerPage,
        sort: sort()
      }, onSuccess, onError);
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        if (vm.predicate !== 'id') {
          result.push('id');
        }
        return result;
      }

      function onSuccess(data, headers) {
        vm.links = ParseLinks.parse(headers('link'));
        vm.totalItems = headers('X-Total-Count');
        vm.queryCount = vm.totalItems;
        vm.tasks = data;
        vm.page = pagingParams.page;
      }

      function onError(error) {
        AlertService.error(error.data.message);
      }
    }
  }
})();
