(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('DocumentsDialogController', DocumentsDialogController);

    DocumentsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Upload', 'entity', 'DateUtils', 'Document', 'Member', 'CategoryUtils'];

    function DocumentsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Upload, entity, DateUtils, Document, Member, CategoryUtils) {
        var vm = this;

        vm.document = entity;
        if (!vm.document.name) {
            vm.document.memberId = $stateParams.id;
            vm.document.loadedById = $stateParams.loadedBy;
        }

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.categories = CategoryUtils.query({member: vm.document.memberId, category: 'documents'});
        vm.dateformat = DateUtils.datetimeformat;

        vm.memberDetails = Member.get({id: vm.document.memberId});
        vm.loadedByDetails = Member.get({id: vm.document.loadedById});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.document.name == null) {
                vm.document.name = vm.file.name;
                uploadUsingUpload(vm.file, vm.document);
            } else {
                Document.save(vm.document, onSaveSuccess, onSaveError);
            }
        }

        function uploadUsingUpload(file, document) {
            file.upload = Upload.upload({
                url: 'api/documents/upload',
                headers: {
                    'Content-Type': undefined
                },
                data: {document: angular.toJson(document), file: file}
            });

            file.upload.then(onSaveSuccess, function (response) {
                if (response.status > 0) {
                    $scope.errorMsg = response.status + ': ' + response.data;
                }
                onSaveError();
            }, function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };
        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:documentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.documentDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
