(function() {
  'use strict';

  angular.module('adf.widget.iframe', ['adf.provider'])
    .config(["dashboardProvider", function (dashboardProvider) {
      dashboardProvider
        .widget('iframe', {
          title: 'iframe',
          description: 'Embed an external page into the dashboard',
          templateUrl: '/content/widgets/iframe/view.html',
          controller: 'iframeController',
          controllerAs: 'iframe',
          edit: {
            templateUrl: '/content/widgets/iframe/edit.html'
          },
          config: {
            height: '420px'
          }
        });
    }])
    .controller('iframeController', iframeController);

    iframeController.$inject = ['$sce', 'config'];
    function iframeController ($sce, config) {
      if (config.url) {
        this.url = $sce.trustAsResourceUrl(config.url);
      }
    }
})();
