(function() {
    'use strict';

    angular
	.module('jobTicketApp')
    .controller('JobDetailController',JobDetailController);
    
    JobDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Job'];

    function JobDetailController($scope, $rootScope, $stateParams, entity, Job) {
        var vm = this;
        vm.job = entity;

        var unsubscribe = $rootScope.$on('jobTicketApp:jobUpdate', function(event, result) {
            vm.job = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
