(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveyAnswerDialogController', SurveyAnswerDialogController);

    SurveyAnswerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SurveyAnswer', 'StatusValue', 'SurveyQuestion'];

    function SurveyAnswerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SurveyAnswer, StatusValue, SurveyQuestion) {
        var vm = this;

        vm.surveyAnswer = entity;
        vm.clear = clear;
        vm.save = save;
        vm.statusvalues = StatusValue.query();
        vm.surveyquestions = SurveyQuestion.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.surveyAnswer.id !== null) {
                SurveyAnswer.update(vm.surveyAnswer, onSaveSuccess, onSaveError);
            } else {
                SurveyAnswer.save(vm.surveyAnswer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:surveyAnswerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
