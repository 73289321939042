(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('TasksDetailController', TasksDetailController);

    TasksDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Task', 'Member', 'ActionCycle', 'Action', 'StatusResult'];

    function TasksDetailController($scope, $rootScope, $stateParams, previousState, entity, Task, Member, ActionCycle, Action, StatusResult) {
        var vm = this;

        vm.task = entity;
        vm.previousState = previousState.name;

        $rootScope.back = function() {
            $state.go(vm.previousState.name,vm.previousState.params);
        };

        var unsubscribe = $rootScope.$on('jobTicketApp:taskUpdate', function(event, result) {
            vm.task = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
