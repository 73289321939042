(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('StatusValueGroupDetailController', StatusValueGroupDetailController);

    StatusValueGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'StatusValueGroup', 'StatusValue'];

    function StatusValueGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, StatusValueGroup, StatusValue) {
        var vm = this;

        vm.statusValueGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:StatusValueGroupUpdate', function(event, result) {
            vm.statusValueGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
