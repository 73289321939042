(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveyDialogController', SurveyDialogController);

    SurveyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Survey', 'SurveyResponse', 'Member', 'SurveyDefinition', 'StatusResult'];

    function SurveyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Survey, SurveyResponse, Member, SurveyDefinition, StatusResult) {
        var vm = this;

        vm.survey = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.surveyresponses = SurveyResponse.query();
        vm.members = Member.query();
        vm.surveydefinitions = SurveyDefinition.query();
        vm.statusresults = StatusResult.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.survey.id !== null) {
                Survey.update(vm.survey, onSaveSuccess, onSaveError);
            } else {
                Survey.save(vm.survey, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:surveyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateTaken = false;
        vm.datePickerOpenStatus.modifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
