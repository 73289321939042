(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('SurveySectionDetailController', SurveySectionDetailController);

    SurveySectionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SurveySection', 'Action', 'StatusValue', 'SurveyDefinition'];

    function SurveySectionDetailController($scope, $rootScope, $stateParams, previousState, entity, SurveySection, Action, StatusValue, SurveyDefinition) {
        var vm = this;

        vm.surveySection = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('jobTicketApp:surveySectionUpdate', function(event, result) {
            vm.surveySection = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
