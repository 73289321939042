(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('ReportsDialogController', ReportsDialogController);

    ReportsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Upload', 'entity', 'DateUtils', 'Report', 'Member', 'CategoryUtils'];

    function ReportsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Upload, entity, DateUtils, Report, Member, CategoryUtils) {
        var vm = this;

        vm.report = entity;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.categories = CategoryUtils.query({category: 'reports'});
        vm.dateformat = DateUtils.datetimeformat;

        Member.get({
            id: 'current'
        }, onCurrentMemberSuccess);
        function onCurrentMemberSuccess(data) {
            vm.loadedByDetails = data;
            if (!vm.report.loadedById) {
                vm.report.loadedById = vm.loadedByDetails.id;
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.report.name == null) {
                vm.report.name = vm.file.name;
                uploadUsingUpload(vm.file, vm.report);
            } else {
                Report.save(vm.report, onSaveSuccess, onSaveError);
            }
        }

        function uploadUsingUpload(file, report) {
            file.upload = Upload.upload({
                url: 'api/reports/upload',
                headers: {
                    'Content-Type': undefined
                },
                data: {report: angular.toJson(report), file: file}
            });

            file.upload.then(onSaveSuccess, function (response) {
                if (response.status > 0) {
                    $scope.errorMsg = response.status + ': ' + response.data;
                }
                onSaveError();
            }, function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };
        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:reportUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.reportDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
