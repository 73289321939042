(function() {
    'use strict';
    angular
        .module('jobTicketApp')
        .factory('MemberSummaries', MemberSummaries);

    MemberSummaries.$inject = ['$resource', 'DateUtils'];

    function MemberSummaries ($resource, DateUtils) {
        var resourceUrl =  'api/process/member-summaries/:memberType/:startStatus/:endStatus/:reportMonth';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                        for (var i = 0; i < data.memberSummaries.length; i++) {
                            data.memberSummaries[i].openDate = DateUtils.convertDateTimeFromServer(data.memberSummaries[i].openDate);
                            data.memberSummaries[i].completeDate = DateUtils.convertDateTimeFromServer(data.memberSummaries[i].completeDate);
                        }
                    }
                    return data;
                }
            }
        });
    }
})();
