(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MemberTypeDialogController', MemberTypeDialogController);

    MemberTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MemberType', 'Action', 'SurveyDefinition'];

    function MemberTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MemberType, Action, SurveyDefinition) {
        var vm = this;

        vm.memberType = entity;
        vm.clear = clear;
        vm.save = save;
        vm.membertypes = MemberType.query();
        vm.surveyDefinitions = SurveyDefinition.query();
        vm.actions = Action.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.memberType.id !== null) {
                MemberType.update(vm.memberType, onSaveSuccess, onSaveError);
            } else {
                MemberType.save(vm.memberType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:memberTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
