(function () {
    'use strict';
    angular
        .module('jobTicketApp')
        .factory('Report', Report);

    Report.$inject = ['$resource', 'DateUtils'];

    function Report($resource, DateUtils) {
        var resourceUrl = 'api/reports/:member/:category/:name';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        // data.reportDate = DateUtils.convertDateTimeFromServer(data.reportDate);
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
