(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('NotesDialogController', NotesDialogController);

    NotesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DateUtils', 'Note', 'Member', 'CategoryUtils', 'NoteTemplate'];

    function NotesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DateUtils, Note, Member, CategoryUtils, NoteTemplate) {
        var vm = this;

        vm.note = entity;
        if (!vm.note.id) {
            vm.note.memberId = $stateParams.id;
            vm.note.takenById = $stateParams.takenBy;
        }

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.categories = CategoryUtils.query({member: vm.note.memberId, category: 'notes'});
        vm.dateformat = DateUtils.datetimeformat;

        vm.memberDetails = Member.get({id: vm.note.memberId});
        vm.takenByDetails = Member.get({id: vm.note.takenById});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.note.id !== null) {
                Note.update(vm.note, onSaveSuccess, onSaveError);
            } else {
                Note.save(vm.note, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:noteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.noteDate = false;
        vm.datePickerOpenStatus.resolutionDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
