(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .directive('surveySections', surveySections);

    function surveySections() {

        var directive = {
            restrict: 'E',
            scope: {
                memberSurveyDetails: '='
            },
            templateUrl: 'app/process/surveys/survey-sections.html'
        };

        return directive;
    }

})();
