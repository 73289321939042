(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('MemberDialogController', MemberDialogController);

    MemberDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Member', 'Address', 'Note', 'Contact', 'Link', 'StatusResult', 'Category', 'MemberType', 'Action', 'Milestone', 'User'];

    function MemberDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Member, Address, Note, Contact, Link, StatusResult, Category, MemberType, Action, Milestone, User) {
        var vm = this;

        vm.member = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.members = Member.query();
        vm.addresses = Address.query();
        vm.notes = Note.query();
        vm.contacts = Contact.query();
        vm.links = Link.query();
        vm.milestones = Milestone.query();
        vm.statusresults = StatusResult.query();
        vm.categories = Category.query();
        vm.users = User.query();
        vm.membertypes = MemberType.query();
        vm.actions = Action.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.member.id !== null) {
                Member.update(vm.member, onSaveSuccess, onSaveError);
            } else {
                Member.save(vm.member, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jobTicketApp:memberUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.startDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
