(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .factory('ProcessInfoService', ProcessInfoService);

    ProcessInfoService.$inject = ['$rootScope', '$http', 'Principal'];

    function ProcessInfoService($rootScope, $http, Principal) {

        var dataPromise;

        var process = null;

        var service = {
            getProcessInfo: getProcessInfo,
            getProcess: getProcess
        };

        $rootScope.$on('logoutSuccess', function () {
            dataPromise = null;
            process = null;
            $rootScope.$broadcast('processInfoLoaded');
        });

        $rootScope.$on('authenticationSuccess', function () {
            if (process != null) {
                $rootScope.$broadcast('processInfoLoaded');
            } else {
                getProcessInfo();
            }
        });

        getProcessInfo();

        return service;

        function getProcessInfo() {
            if (!Principal.isAuthenticated()) {
                if (process != null) {
                    process = null;
                    $rootScope.$broadcast('processInfoLoaded');
                }
                return null;
            }

            dataPromise = $http.get('api/process-info').then(function (result) {
                if (result.data.code) {
                    var response = {};
                    response.id = result.data.id;
                    response.active = result.data.active;
                    response.code = result.data.code;
                    response.name = result.data.name;
                    response.description = result.data.description;
                    response.theme = result.data.theme;
                    response.version = result.data.version;

                    process = response;
                    $rootScope.$broadcast('processInfoLoaded');

                    return response;
                }
            });
            return dataPromise;
        }

        function getProcess() {
            return process;
        }
    }
})();
