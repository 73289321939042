(function() {
    'use strict';

    angular
        .module('jobTicketApp')
        .controller('JobDialogController', JobDialogController);

    JobDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Job', 'JobDefinition'];

    function JobDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Job, JobDefinition) {

        var vm = this;
        vm.job = entity;
        vm.jobDefinitions = JobDefinition.query();
        vm.commandData = {};
        vm.selectedJobDefinition = {};

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.cronOptions = {
            "quartz": true
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('jobTicketApp:jobUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            //if (vm.job.name != null) {
            //    Job.update(vm.job, onSaveSuccess, onSaveError);
            //} else {
                Job.save(vm.job, onSaveSuccess, onSaveError);
            //}
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerForStartDate = {};

        vm.datePickerForStartDate.status = {
            opened: false
        };

        vm.datePickerForStartDateOpen = function ($event) {
            vm.datePickerForStartDate.status.opened = true;
        };
        vm.datePickerForEndDate = {};

        vm.datePickerForEndDate.status = {
            opened: false
        };

        vm.datePickerForEndDateOpen = function ($event) {
            vm.datePickerForEndDate.status.opened = true;
        };

        vm.addCommandData = function (commandData) {
            if (commandData.key && commandData.value) {
                vm.job.commandData[commandData.key] = commandData.value;
            }
        };
        vm.removeCommandData = function (commandKey) {
            delete vm.job.commandData[commandKey];
        };

        vm.updateCommandClass = function () {
            vm.job.commandClass = vm.selectedJobDefinition.commandClass;
            vm.job.commandData = {};

            Object.keys(vm.selectedJobDefinition.properties).forEach(function (key, index) {
                vm.job.commandData[key] = vm.selectedJobDefinition.properties[key];
            });
        };

        vm.updateCommandData = function (key, value) {
            vm.job.commandData[key] = value;
        };
    }
})();
